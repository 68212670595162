import styled from "styled-components";
const KeyFeaturesArea = styled.section`
  background: linear-gradient(-45deg, #132636, #132636, #132636, #f4ba4f);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  padding-bottom: 120px;
  padding-top: 120px;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  @media (max-width: 768px) {
    padding-top: 60px;
  }
  .container {
    max-width: 1000px;
    .blockTitle {
      text-align: center;
      max-width: 577px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 70px;
      @media (max-width: 1600px) {
        margin-bottom: 40px;
      }
      h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 1.31;
        letter-spacing: -0.5px;
        color: white;
        margin: 0;
        margin-bottom: 15px;
        @media (max-width: 1600px) {
          font-size: 30px;
          margin-bottom: 55px;
          line-height: 1.3;
          max-width: 468px;
          margin-left: auto;
          margin-right: auto;
        }
        @media (max-width: 768px) {
          font-size: 28px;
        }
        @media (max-width: 375px) {
          font-size: 22px;
        }
      }
      p {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 2;
        color: #132636;
        @media (max-width: 768px) {
          font-size: 15px;
        }
      }
    }
  }
  .servicePost {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-top: 40px;
    }
    > img {
      flex-shrink: 0;
      width: 70px;
      height: 70px;
    }
    .content {
      margin-left: 20px;
      h3 {
        margin: 0;
        font-weight: bold;
        font-size: 22px;
        line-height: 1.27;
        color: white;
        margin-bottom: 10px;
        @media (max-width: 425px) {
          font-size: 16px;
        }
      }
      p {
        font-weight: normal;
        font-size: 15px;
        line-height: 1.87;
        color: white;
        margin: 0;
      }
    }
    &:hover {
      .service-icon {
        animation: var(--shakeAnim);
      }
    }
  }
  .service-icon {
    width: var(--width);
    flex: 0 0 var(--width);
  }
`;
export default KeyFeaturesArea;

export const Row = styled.div`
  margin-left: -44px;
  margin-right: -44px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 3px 4px rgb(100 135 167 / 8%);
  border-radius: 15px;
  border-style: solid;
  border-color: white;
  @media (max-width: 1199px) {
    padding-left: 40px;
    padding-right: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 480px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
export const Col = styled.div`
  padding-left: 44px;
  padding-right: 44px;
  flex: 0 0 33.33%;
  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 575px) {
    flex: 0 0 100%;
  }
`;
