import Sticky from "react-stickynode";
import Navbar from "../containers/SecD3v/Navbar";
import { DrawerProvider } from "../common/contexts/DrawerContext";
import SEO from "../components/SEO";
import OurProduct from "../containers/SecD3v/OurProduct";
import KeyFeatures from "../containers/SecD3v/KeyFeatures";
import KeyBenefits from "../containers/SecD3v/KeyBenefits";
import PricingTable from "../containers/SecD3v/PricingTable";

import Banner from "../containers/SecD3v/Contact/Banner";
import Section from "../containers/SecD3v/Contact/Section";
import Footer from "../containers/SecD3v/Footer";

// import Head from "next/head";
import React from "react";

const SecD3v = () => {
  return (
    <>
      <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
        <DrawerProvider>
          <Navbar />
        </DrawerProvider>
      </Sticky>
      <SEO title="Contact" />
      <Banner />
      <Section />
      <Footer />
    </>
  );
};

export default SecD3v;
