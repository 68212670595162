import React, { useState } from "react";
import { Link } from "gatsby";
import Heading from "../../../common/components/Heading";

import FormArea, {
  Container,
  CardFooter,
  CardTop,
  Col,
  PriceCard,
  PricingAmount,
  Row,
  TopHeading,
} from "./register.style";

const KeyBenefitsList = (object) => {
  return (
    <>
      <FormArea>
        <Container>
          <>
            <Heading as="h4" content={object.value.title} />
            {object.value.description}
          </>
        </Container>
      </FormArea>
    </>
  );
};

export default KeyBenefitsList;
